<template>
    <div>
        <h2 class="mb-1">Discharge Data Report</h2>

        <div style="display: flex; flex-wrap: wrap; align-items: center;" class="mb-2">
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon mr-50"
                style="height: 37px; margin-top: 12px;"
                @click.stop.prevent="prevRange()"
            >
                <feather-icon icon="ChevronLeftIcon" />
            </b-button>

            <b-form-group
                label="Start Date"
                class="mr-50 w-10"
            >
                <date-picker v-model="startDate" type="month" format="MM-DD-YYYY" value-type="DD/MM/YYYY" v-if="selectedRangeDate === 'month' || selectedRangeDate === 'quarter'"></date-picker>
                <date-picker v-model="startDate" type="year" format="MM-DD-YYYY" value-type="DD/MM/YYYY" v-else-if="selectedRangeDate === 'year'"></date-picker>
                <date-picker v-model="startDate" type="date" format="MM-DD-YYYY" value-type="DD/MM/YYYY" v-else-if="!selectedRangeDate || selectedRangeDate === 'custom'"></date-picker>
            </b-form-group>
            <b-form-group
                label="End Date"
                class="mr-50"
            >
                <date-picker v-model="endDate" type="date" format="MM-DD-YYYY" value-type="DD/MM/YYYY" :disabled="selectedRangeDate === 'month' || selectedRangeDate === 'quarter' || selectedRangeDate === 'year' ? true : false"></date-picker>
            </b-form-group>

            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon ml-0"
                style="height: 37px; margin-top: 12px;"
                @click.stop.prevent="nextRange()"
            >
                <feather-icon icon="ChevronRightIcon" />
            </b-button>

            <div :class="!selectedRangeDate ? 'pl-1' : 'pl-1'">
                <b-form-group>
                    <b-form-radio-group
                        v-model="selectedRangeDate"
                        button-variant="outline-primary range-date"
                        buttons
                        class="p-0 mr-2"
                    >
                        <b-form-radio style="height: 37px; padding: 11px;" v-for="(option,index) in optionsRangeDate" :key="index" :value="option.value">{{option.text}}</b-form-radio>
                        <b-form-radio style="height: 37px; padding: 10px;" v-if="selectedRangeDate" value="x">x</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>

            <b-form-group
                label="Region"
            >
                <v-select
                    v-model="region"
                    placeholder="Region"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsRegion"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>

            <div v-if="region === 'NYC'">
                <b-form-group
                    label="Borough"
                >
                    <v-select
                        v-model="borough"
                        placeholder="Any Borough"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="optionsBorough"
                        style="background: #fff; min-width: 200px;"
                        class="mr-2"
                    />
                </b-form-group>
            </div>
            
            <b-form-group
                label="Progress"
            >
                <v-select
                    v-model="progress"
                    placeholder="Progress"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsClientProgress"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>
            
            <b-form-group
                label="Language"
            >
                <v-select
                    v-model="language"
                    placeholder="Language"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsLanguage"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>
            
            <b-form-group
                label="Insurance"
            >
                <v-select
                    v-model="insurance"
                    placeholder="Insurance"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsInsurance"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>

            <b-form-group
                label="Program"
            >
                <b-form-select
                    v-model="clinicianProgram"
                    inline
                    :options="optionsProgram"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    style="min-width: 200px;"
                    class="d-inline-block mr-2"
                />
            </b-form-group>

            <b-form-group
                label="Discharge Status"
                class="ml-2"
            >
                <v-select
                    v-model="dischargeStatus"
                    placeholder="Discharge Status"
                    :options="optionsDischargeStatus"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    style="background: #fff; min-width: 200px;"
                    class=" mr-2"
                />
            </b-form-group>

            <b-form-group
                label="Planned discharge reasons"
            >
                <v-select
                    v-model="plannedDischargeReasons"
                    placeholder="Planned discharge reasons"
                    :options="optionsPlannedDischargeReasons"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>

            <b-form-group
                label="Discharge Disposition"
            >
                <v-select
                    v-model="dischargeDisposition"
                    placeholder="Discharge Disposition"
                    :options="optionsDischargeDisposition"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>

            <b-form-group
                label="Referral Disposition"
            >
                <v-select
                    v-model="referralDisposition"
                    placeholder="Referral Disposition"
                    :options="optionsReferralDisposition"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>

            <b-form-group
                label="DCM at Admission was Reduced"
            >
                <v-select
                    v-model="diagnosticCriteriaMetAtAdmissionWasReduced"
                    placeholder="Diagnostic Criteria Met at Admission was Reduced"
                    :options="optionsDiagnosticCriteriaMetAtAdmissionWasReduced"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>

            <b-form-group
                label="DCM at Discharge"
            >
                <v-select
                    v-model="diagnosticCriteriaMetAtDischarge"
                    placeholder="Diagnostic Criteria Met at Discharge"
                    :options="optionsDiagnosticCriteriaMetAtAdmission"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>

            <b-form-group
                label="CGC to Admission"
            >
                <v-select
                    v-model="currentGamblingComparedToAdmission"
                    placeholder="Current Gambling Compared to Admission"
                    :options="optionsCurrentGamblingComparedToAdmission"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>

            <b-form-group
                label="CI w/Self-Help at Discharge"
            >
                <v-select
                    v-model="clientInvolvedWithSelfHelpAtDischarge"
                    placeholder="Client Involved with Self-Help at Discharge"
                    :options="optionsClientInvolvedWithSelfHelpAtDischarge"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>

            <b-form-group
                label="CR for Continued Services"
            >
                <v-select
                    v-model="clientReferredForContinuedServices"
                    placeholder="Client Referred for Continued Services"
                    :options="optionsClientReferredForContinuedServices"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>

            <b-form-group
                label="Gambling"
            >
                <v-select
                    v-model="relatedProblemsGambling"
                    placeholder="Gambling"
                    :options="optionsGamblingRelatedProblems"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>

            <b-form-group
                label="Drug Use"
            >
                <v-select
                    v-model="relatedProblemsDrugUse"
                    placeholder="Drug Use"
                    :options="optionsGamblingRelatedProblems"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>

            <b-form-group
                label="Alcohol Use"
            >
                <v-select
                    v-model="relatedProblemsAlcoholUse"
                    placeholder="Alcohol Use"
                    :options="optionsGamblingRelatedProblems"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>

            <b-form-group
                label="Financial"
            >
                <v-select
                    v-model="relatedProblemsFinancial"
                    placeholder="Financial"
                    :options="optionsGamblingRelatedProblems"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>

            <b-form-group
                label="Legal"
            >
                <v-select
                    v-model="relatedProblemsLegal"
                    placeholder="Legal"
                    :options="optionsGamblingRelatedProblems"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>

            <b-form-group
                label="Vocational/Educational"
            >
                <v-select
                    v-model="relatedProblemsVocationalEducational"
                    placeholder="Vocational/Educational"
                    :options="optionsGamblingRelatedProblems"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>

            <b-form-group
                label="Medical/Health"
            >
                <v-select
                    v-model="relatedProblemsMedicalHealth"
                    placeholder="Medical/Health"
                    :options="optionsGamblingRelatedProblems"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>

            <b-form-group
                label="Lethality: Self"
            >
                <v-select
                    v-model="relatedProblemsLethalitySelf"
                    placeholder="Lethality: Self"
                    :options="optionsGamblingRelatedProblems"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>

            <b-form-group
                label="Lethality: Other"
            >
                <v-select
                    v-model="relatedProblemsLethalityOther"
                    placeholder="Lethality: Other"
                    :options="optionsGamblingRelatedProblems"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>

            <b-form-group
                label="Mental Health"
            >
                <v-select
                    v-model="relatedProblemsMentalHealth"
                    placeholder="Mental Health"
                    :options="optionsGamblingRelatedProblems"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>

            <b-form-group
                label="Family"
            >
                <v-select
                    v-model="relatedProblemsFamily"
                    placeholder="Family"
                    :options="optionsGamblingRelatedProblems"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>

            <div>
                <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-primary"
                    class="mb-1 mt-2 p-75"
                    @click="activeFilterFunction"
                >
                    <feather-icon
                        icon="Xicon"
                        class="mr-50"
                    />
                    <span class="align-middle">Reset filters</span>
                </b-button>
            </div>
        <!-- </b-row> -->
        </div>

        <b-row>
            <!-- Total Discharges -->
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countTotalDischarges && countTotalDischarges !== 'NaN' ? countTotalDischarges.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Total Discharges</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <!-- Total Planned Discharges -->
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countTotalDischargeStatusPlanned && countTotalDischargeStatusPlanned !== 'NaN' ? countTotalDischargeStatusPlanned.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Total Planned Discharges</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <!-- Total Dropout Discharges -->
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countTotalDischargeStatusDropout && countTotalDischargeStatusDropout !== 'NaN' ? countTotalDischargeStatusDropout.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Total Dropout Discharges</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <!-- Average Sessions Completed -->
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countAverageSessionsCompleted && countAverageSessionsCompleted !== 'NaN' ? countAverageSessionsCompleted.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Average Sessions Completed</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <!-- Goals Achieved -->
            <!-- <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countGoalsAchieved && countGoalsAchieved !== 'NaN' ? countGoalsAchieved.toLocaleString('en-US') : 0 }}%
                        </h2>
                        <span>Goals Achieved</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col> -->

            <!-- Goals Partially Achieved -->
            <!-- <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countGoalsPartiallyAchieved && countGoalsPartiallyAchieved !== 'NaN' ? countGoalsPartiallyAchieved.toLocaleString('en-US') : 0 }}%
                        </h2>
                        <span>Goals Partially Achieved</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col> -->

            <!-- No Longer Gambling or Reduced -->
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        <!-- {{ totalRemoteSessions }} -->
                        {{ countNoLongerGamblingorReduced && countNoLongerGamblingorReduced !== 'NaN' ? countNoLongerGamblingorReduced.toLocaleString('en-US') : 0 }}%
                        </h2>
                        <span>No Longer Gambling or Reduced</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            
            <!-- Diagnostic Criteria Reduced -->
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        <!-- {{ totalRemoteSessions }} -->
                        {{ countDiagnosticCriteriaReduced && countDiagnosticCriteriaReduced !== 'NaN' ? countDiagnosticCriteriaReduced.toLocaleString('en-US') : 0 }}%
                        </h2>
                        <span>Diagnostic Criteria Reduced</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <!-- Average Length Of Stay -->
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ averageLengthOfStay && averageLengthOfStay !== 'NaN' ? averageLengthOfStay.toFixed(2) : 0 }}  months
                        </h2>
                        <span>Average Length Of Stay</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countDischargeSCTAllGM && countDischargeSCTAllGM !== 'NaN' ? countDischargeSCTAllGM.toFixed(0) : 0 }}
                        </h2>
                        <span>Discharge Status/Completed Treatment, All Goals Met</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countDischargeSCTHMGM && countDischargeSCTHMGM !== 'NaN' ? countDischargeSCTHMGM.toFixed(0) : 0 }}
                        </h2>
                        <span>Discharge Status/Completed Treatment, Half or More Goals Met</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countDischargeTNCMBCD && countDischargeTNCMBCD !== 'NaN' ? countDischargeTNCMBCD.toFixed(0) : 0 }}
                        </h2>
                        <span>Discharge Status/Treatment Not Completed, Max Benefit/Clinical Decision</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countDischargeTNCSGM && countDischargeTNCSGM !== 'NaN' ? countDischargeTNCSGM.toFixed(0) : 0 }}
                        </h2>
                        <span>Discharge Status/Treatment Not Completed, Some Goals Met</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countDischargeTNCNGM && countDischargeTNCNGM !== 'NaN' ? countDischargeTNCNGM.toFixed(0) : 0 }}
                        </h2>
                        <span>Discharge Status/Treatment Not Completed, No Goals Met</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countDischargeBAS && countDischargeBAS !== 'NaN' ? countDischargeBAS.toFixed(0) : 0 }}
                        </h2>
                        <span>Planned discharge reasons/Barriers to accessing services</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countDischargeCNRT && countDischargeCNRT !== 'NaN' ? countDischargeCNRT.toFixed(0) : 0 }}
                        </h2>
                        <span>Planned discharge reasons/Client not ready for treatment</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countDischargeCRI && countDischargeCRI !== 'NaN' ? countDischargeCRI.toFixed(0) : 0 }}
                        </h2>
                        <span>Planned discharge reasons/Client reports improvement</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countDischargeCTP && countDischargeCTP !== 'NaN' ? countDischargeCTP.toFixed(0) : 0 }}
                        </h2>
                        <span>Planned discharge reasons/Completed treatment process (as determined by Clinician)</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countDischargeMEC && countDischargeMEC !== 'NaN' ? countDischargeMEC.toFixed(0) : 0 }}
                        </h2>
                        <span>Planned discharge reasons/Mandatory evaluation completed</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countDischargeOther && countDischargeOther !== 'NaN' ? countDischargeOther.toFixed(0) : 0 }}
                        </h2>
                        <span>Planned discharge reasons/Other</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countDischargeATTLOCNLN && countDischargeATTLOCNLN !== 'NaN' ? countDischargeATTLOCNLN.toFixed(0) : 0 }}
                        </h2>
                        <span>Discharge Disposition/Additional treatment at this level of care no longer necessary</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countDischargeFTATLOCUTY && countDischargeFTATLOCUTY !== 'NaN' ? countDischargeFTATLOCUTY.toFixed(0) : 0 }}
                        </h2>
                        <span>Discharge Disposition/Further treatment at this level of care unlikely to yield additional clinical gains</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countDischargeLACAFRMO && countDischargeLACAFRMO !== 'NaN' ? countDischargeLACAFRMO.toFixed(0) : 0 }}
                        </h2>
                        <span>Discharge Disposition/Left against clinical advice, Formal referral made/offered</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countDischargeLACALCNRP && countDischargeLACALCNRP !== 'NaN' ? countDischargeLACALCNRP.toFixed(0) : 0 }}
                        </h2>
                        <span>Discharge Disposition/Left against clinical advice, Lost contact, No referral possible</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countDischargeLACATOTPF && countDischargeLACATOTPF !== 'NaN' ? countDischargeLACATOTPF.toFixed(0) : 0 }}
                        </h2>
                        <span>Discharge Disposition/Left against clinical advice, Termination of third party funds</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countDischargeDDTNPR && countDischargeDDTNPR !== 'NaN' ? countDischargeDDTNPR.toFixed(0) : 0 }}
                        </h2>
                        <span>Discharge Disposition/Discharged due to non-compliance, Program Rules</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countDischargeDDTNCV && countDischargeDDTNCV !== 'NaN' ? countDischargeDDTNCV.toFixed(0) : 0 }}
                        </h2>
                        <span>Discharge Disposition/Discharged due to non-compliance, Violence</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countDischargeDDTNCPOC && countDischargeDDTNCPOC !== 'NaN' ? countDischargeDDTNCPOC.toFixed(0) : 0 }}
                        </h2>
                        <span>Discharge Disposition/Discharged due to non-compliance, Possession of contraband</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countDischargeCAI && countDischargeCAI !== 'NaN' ? countDischargeCAI.toFixed(0) : 0 }}
                        </h2>
                        <span>Discharge Disposition/Client arrested/incarcerated</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countDischargeCCNLPFMPR && countDischargeCCNLPFMPR !== 'NaN' ? countDischargeCCNLPFMPR.toFixed(0) : 0 }}
                        </h2>
                        <span>Discharge Disposition/Client could no longer participate for medical/psychiatric reasons</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countDischargeCD && countDischargeCD !== 'NaN' ? countDischargeCD.toFixed(0) : 0 }}
                        </h2>
                        <span>Discharge Disposition/Client death</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countDischargeCR && countDischargeCR !== 'NaN' ? countDischargeCR.toFixed(0) : 0 }}
                        </h2>
                        <span>Discharge Disposition/Client relocated</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countDischargePC && countDischargePC !== 'NaN' ? countDischargePC.toFixed(0) : 0 }}
                        </h2>
                        <span>Discharge Disposition/Program closed</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countDispositionNRM && countDispositionNRM !== 'NaN' ? countDispositionNRM.toFixed(0) : 0 }}
                        </h2>
                        <span>Referral Disposition/Discharge Disposition/No referral made</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countDispositionCNINOAS && countDispositionCNINOAS !== 'NaN' ? countDispositionCNINOAS.toFixed(0) : 0 }}
                        </h2>
                        <span>Referral Disposition/Client not in need of additional services</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countDispositionCRBTSP && countDispositionCRBTSP !== 'NaN' ? countDispositionCRBTSP.toFixed(0) : 0 }}
                        </h2>
                        <span>Referral Disposition/Client referred back to SUD program</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countDispositionCRTOSP && countDispositionCRTOSP !== 'NaN' ? countDispositionCRTOSP.toFixed(0) : 0 }}
                        </h2>
                        <span>Referral Disposition/Client referred to other SUD program</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countDispositionCRTMHP && countDispositionCRTMHP !== 'NaN' ? countDispositionCRTMHP.toFixed(0) : 0 }}
                        </h2>
                        <span>Referral Disposition/Client referred to mental health program</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countDispositionCRTOPGP && countDispositionCRTOPGP !== 'NaN' ? countDispositionCRTOPGP.toFixed(0) : 0 }}
                        </h2>
                        <span>Referral Disposition/Client referred to other problem gambling program</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countDispositionCRR && countDispositionCRR !== 'NaN' ? countDispositionCRR.toFixed(0) : 0 }}
                        </h2>
                        <span>Referral Disposition/Client refused referral</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countCGCTANA && countCGCTANA !== 'NaN' ? countCGCTANA.toFixed(0) : 0 }}
                        </h2>
                        <span>Current Gambling Compared to Admission/N/A</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countCGCTANLG && countCGCTANLG !== 'NaN' ? countCGCTANLG.toFixed(0) : 0 }}
                        </h2>
                        <span>Current Gambling Compared to Admission/No longer gambling</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countCGCTARG && countCGCTARG !== 'NaN' ? countCGCTARG.toFixed(0) : 0 }}
                        </h2>
                        <span>Current Gambling Compared to Admission/Reduced gambling</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countCGCTASG && countCGCTASG !== 'NaN' ? countCGCTASG.toFixed(0) : 0 }}
                        </h2>
                        <span>Current Gambling Compared to Admission/Same gambling</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countCGCTAWG && countCGCTAWG !== 'NaN' ? countCGCTAWG.toFixed(0) : 0 }}
                        </h2>
                        <span>Current Gambling Compared to Admission/Worse gambling</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countCRCSPGSHGM && countCRCSPGSHGM !== 'NaN' ? countCRCSPGSHGM.toFixed(0) : 0 }}
                        </h2>
                        <span>Client Referred for Continued Services/Problem Gambling Self-Help Group Meetings</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countCRCSSUSHGM && countCRCSSUSHGM !== 'NaN' ? countCRCSSUSHGM.toFixed(0) : 0 }}
                        </h2>
                        <span>Client Referred for Continued Services/Substance Use Self-Help Group Meetings</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countCRCSFC && countCRCSFC !== 'NaN' ? countCRCSFC.toFixed(0) : 0 }}
                        </h2>
                        <span>Client Referred for Continued Services/Financial Counseling</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countCRCSCS && countCRCSCS !== 'NaN' ? countCRCSCS.toFixed(0) : 0 }}
                        </h2>
                        <span>Client Referred for Continued Services/Crisis Services</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countCRCSPGTS && countCRCSPGTS !== 'NaN' ? countCRCSPGTS.toFixed(0) : 0 }}
                        </h2>
                        <span>Client Referred for Continued Services/Problem Gambling Treatment Services</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countCRCSSUTS && countCRCSSUTS !== 'NaN' ? countCRCSSUTS.toFixed(0) : 0 }}
                        </h2>
                        <span>Client Referred for Continued Services/Substance Use Treatment Services</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countCRCSRSS && countCRCSRSS !== 'NaN' ? countCRCSRSS.toFixed(0) : 0 }}
                        </h2>
                        <span>Client Referred for Continued Services/Recovery Support Services</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countCRCSO && countCRCSO !== 'NaN' ? countCRCSO.toFixed(0) : 0 }}
                        </h2>
                        <span>Client Referred for Continued Services/Other</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>

        <download-csv 
            :data="json_data"
            class="mb-1 btn btn-default"
            name="reports-discharge-data.csv"
            :style="readyToExport ? 'background: #94221e; color: #fff; cursor: pointer; opacity: 1;' : 'background: #94221e; color: #fff; cursor: no-drop; opacity: 0.5;'"
            delimiter=","
            v-if="isDataSpecialist"
        >
        <!-- v-if="isDataSpecialist" -->
            CSV Export
            <b-spinner small v-if="!readyToExport" />
        </download-csv>
    </div>
</template>

<script>
import { 
    BForm, BButton, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BTable, BFormInvalidFeedback, BFormSelect, BCard, BAvatar, BCardBody,
    BSpinner, BFormRadioGroup, BFormRadio,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapActions, mapGetters, mapState } from 'vuex'
import moment from 'moment'
import { db, auth } from '@/firebase'

export default {
    data() {
        return {
            // validation
            required,
            email,
            totalRows: 0,
            isDataSpecialist: false,
            readyToExport: false,

            // filters
            startDate: null,
            endDate: null,
            selectedRangeDate: 'month',
            optionsRangeDate: [
                { text: 'Month', value: 'month' },
                { text: 'Quarter', value: 'quarter' },
                { text: 'Year', value: 'year' },
                { text: 'Custom', value: 'custom' },
            ],
            region: '',
            borough: '',
            language: '',
            progress: '',
            insurance: '',

            // data reports
            countTotalDischarges : 0,
            countTotalDischargeStatusPlanned : 0,
            countTotalDischargeStatusDropout : 0,
            countAverageSessionsCompleted : 0,
            countGoalsAchieved : 0,
            countGoalsPartiallyAchieved : 0,
            countNoLongerGamblingorReduced: 0,
            countDiagnosticCriteriaReduced: 0,
            averageLengthOfStay: 0,
            // NEW
            countDischargeSCTAllGM: 0,
            countDischargeSCTHMGM: 0,
            countDischargeTNCMBCD: 0,
            countDischargeTNCSGM: 0,
            countDischargeTNCNGM: 0,
            countDischargeBAS: 0,
            countDischargeCNRT: 0,
            countDischargeCRI: 0,
            countDischargeCTP: 0,
            countDischargeMEC: 0,
            countDischargeOther: 0,
            countDischargeATTLOCNLN: 0,
            countDischargeFTATLOCUTY: 0,
            countDischargeLACAFRMO: 0,
            countDischargeLACALCNRP: 0,
            countDischargeLACATOTPF: 0,
            countDischargeDDTNPR: 0,
            countDischargeDDTNCV: 0,
            countDischargeDDTNCPOC: 0,
            countDischargeCAI: 0,
            countDischargeCCNLPFMPR: 0,
            countDischargeCD: 0,
            countDischargeCR: 0,
            countDischargePC: 0,
            countDispositionNRM: 0,
            countDispositionCNINOAS: 0,
            countDispositionCRBTSP: 0,
            countDispositionCRTOSP: 0,
            countDispositionCRTMHP: 0,
            countDispositionCRTOPGP: 0,
            countDispositionCRR: 0,
            countCGCTANA: 0,
            countCGCTANLG: 0,
            countCGCTARG: 0,
            countCGCTASG: 0,
            countCGCTAWG: 0,
            countCRCSPGSHGM: 0,
            countCRCSSUSHGM: 0,
            countCRCSFC: 0,
            countCRCSCS: 0,
            countCRCSPGTS: 0,
            countCRCSSUTS: 0,
            countCRCSRSS: 0,
            countCRCSO: 0,

            // options
            optionsRegion: [],
            // optionsRegion: [
            //     'Bronx',
            //     'Brooklyn',
            //     'Central',
            //     'Finger Lakes',
            //     'Long Island',
            //     'Manhattan',
            //     'Mid-Hudson',
            //     'Northeast',
            //     'Queens',
            //     'Staten Island',
            //     'Western'
            // ],
            optionsLanguage: [],
            optionsClientProgress: [
                'Any Client Status',
                'Engaged',
                'Closed - Attended First Session',
                'Closed - Did not attend first session',
                'Closed - Received information',
                'Closed - Referred to OASAS Program',
                'External Referral',
                'On Hold'
            ],
            optionsInsurance: [],
            optionsBorough: [
                'Bronx',
                'Brooklyn',
                'Manhattan',
                'Queens',
                'Staten Island'
            ],

            // settings table and edit view
            statusEdit: false,
            itemsActivity: [],
            fieldsTable: [
                { key: 'clientID', sortable: false },
                { key: 'clientName', sortable: false },
                { key: 'status', sortable: false },
                { key: 'contactDate', sortable: false },
                { key: 'admissionDate', sortable: false },
                { key: 'dischargeDate', sortable: false },
                // { key: 'actions', sortable: false },
            ],
            clinicianProgram: null,
            optionsProgram: [],

            // get data from db
            optionsClient: [],
            optionsClinician: [],

            // filter
            clientActivityName: null,
            clientActivityStatus: null,

            // json to export csv
            json_data: [],

            // reports cards
            totalGroupSessions: 0,
            totalIndividualSessions: 0,
            totalRemoteSessions: 0,
            totalNonEnglishClients: 0,
            clientSatisfaction: 0,
            improved: 0,
            totalClientActivity: 0,

            // overlay data
            rangeDinamic: false,
            isBusy: true,

            // new filters
            dischargeStatus: '',
            optionsDischargeStatus: [
                'Completed Treatment, All Goals Met',
                'Completed Treatment, Half or More Goals Met',
                'Treatment Not Completed, Max Benefit/Clinical Decision',
                'Treatment Not Completed, Some Goals Met',
                'Treatment Not Completed, No Goals Met'
            ],
            plannedDischargeReasons: '',
            optionsPlannedDischargeReasons: [
                'Barriers to accessing services',
                'Client not ready for treatment',
                'Client reports improvement',
                'Completed treatment process (as determined by Clinician)',
                'Mandatory evaluation completed',
                'Other'
            ],
            dischargeDisposition: '',
            optionsDischargeDisposition: [
                'Additional treatment at this level of care no longer necessary',
                'Further treatment at this level of care unlikely to yield additional clinical gains',
                'Left against clinical advice, Formal referral made/offered',
                'Left against clinical advice, Lost contact, No referral possible',
                'Left against clinical advice, Termination of third party funds',
                'Discharged due to non-compliance, Program Rules',
                'Discharged due to non-compliance, Violence',
                'Discharged due to non-compliance, Possession of contraband',
                'Client arrested/incarcerated',
                'Client could no longer participate for medical/psychiatric reasons',
                'Client death',
                'Client relocated',
                'Program closed',
            ],
            referralDisposition: '',
            optionsReferralDisposition: [
                'No referral made',
                'Client not in need of additional services',
                'Client referred back to SUD program',
                'Client referred to other SUD program',
                'Client referred to mental health program',
                'Client referred to other problem gambling program',
                'Client refused referral',
            ],
            diagnosticCriteriaMetAtAdmissionWasReduced: '',
            optionsDiagnosticCriteriaMetAtAdmissionWasReduced: ['N/A','Yes', 'No'],
            diagnosticCriteriaMetAtDischarge: '',
            optionsDiagnosticCriteriaMetAtAdmission: [
                'N/A',
                'After losing money gambling, often returns another day to get even ("chasing" ones losses)',
                'Has jeopardized or lost a significant relationship, job, or educational or career opportunity because of gambling.',
                'Has made repeated unsuccesful efforts to control, cut back, or stop gambling',
                'Is often preoccupied with (e.g., having persistent thoughts of reliving past gambling experiences, handicapping or planning the next venture, thinking of ways to get money with which to gamble).',
                'Is restless or irritable when attempting to cut down or stop gambling.',
                'Lies to conceal the extent of involvement with gambling.',
                'Often gambles when feeling distressed (e.g., helpless, guilty, anxious, depressed).',
                'Needs to gamble with increasing amounts of money in order to achieve the desired excitement.',
                'Relies on others to provide money to relieve desperate financial situations caused by gambling.'
            ],
            currentGamblingComparedToAdmission: '',
            optionsCurrentGamblingComparedToAdmission: [
                'N/A',
                'No longer gambling',
                'Reduced gambling',
                'Same gambling',
                'Worse gambling'
            ],
            clientInvolvedWithSelfHelpAtDischarge: '',
            optionsClientInvolvedWithSelfHelpAtDischarge: ['Yes', 'No', 'N/A'],
            clientReferredForContinuedServices: '',
            optionsClientReferredForContinuedServices: [
                'Problem Gambling Self-Help Group Meetings',
                'Substance Use Self-Help Group Meetings',
                'Financial Counseling',
                'Crisis Services',
                'Problem Gambling Treatment Services',
                'Substance Use Treatment Services',
                'Recovery Support Services',
                'Other'
            ],
            relatedProblemsGambling: '',
            relatedProblemsDrugUse: '',
            relatedProblemsAlcoholUse: '',
            relatedProblemsFinancial: '',
            relatedProblemsLegal: '',
            relatedProblemsVocationalEducational: '',
            relatedProblemsMedicalHealth: '',
            relatedProblemsLethalitySelf: '',
            relatedProblemsLethalityOther: '',
            relatedProblemsMentalHealth: '',
            relatedProblemsFamily: '',
            optionsGamblingRelatedProblems: [
                'Achieved',
                'Partial Achievement',
                'Not Achieved',
                'Not Applicable'
            ],
        }
    },
    components: {
        BForm,
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BTable,
        BFormInvalidFeedback,
        BFormSelect,
        BCard,
        BAvatar,
        BCardBody,
        BSpinner,
        BFormRadioGroup,
        BFormRadio,
        DatePicker,
        
        // select
        vSelect,

        // date
        flatPickr,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    computed: {
        ...mapGetters(['arrayFiltradoReportsDischargeData']),
        ...mapState(['searchReportsDischargeDataStartDateState','searchReportsDischargeDataEndDateState','searchReportsDischargeDataRegionState','searchReportsDischargeDataProgressState','searchReportsDischargeDataLanguageState','searchReportsDischargeDataInsuranceState','searchReportsDischargeDataProgramState'])
    },
    watch: {
        selectedRangeDate(val) {
            this.functionDateRange(val)
        },
        clientActivityStatus(val) {
            this.searchClientActivityStatus(val)
        },
        startDate(val) {
            this.searchReportsDischargeDataStartDate(val)
            this.functionDateRange(this.selectedRangeDate)
            this.rangeDinamic ? this.rangeDinamic = false : ''
            // this.selectedRangeDate = ''
        },
        endDate(val) {
            this.searchReportsDischargeDataEndDate(val)
        },
        region(val) {
            this.searchReportsDischargeDataRegion(val)
        },
        borough(val) {
            this.searchReportsDischargeDataRegion(val)
        },
        progress(val) {
            this.searchReportsDischargeDataProgress(val)
        },
        language(val) {
            this.searchReportsDischargeDataLanguage(val)
        },
        insurance(val) {
            this.searchReportsDischargeDataInsurance(val)
        },
        clinicianProgram(val) {
            this.searchReportsDischargeDataProgram(val)
        },
        dischargeStatus(val) {
            this.searchReportsDischargeDatadischargeStatus(val)
        },
        plannedDischargeReasons(val) {
            this.searchReportsDischargeDataplannedDischargeReasons(val)
        },
        dischargeDisposition(val) {
            this.searchReportsDischargeDatadischargeDisposition(val)
        },
        referralDisposition(val) {
            this.searchReportsDischargeDatareferralDisposition(val)
        },
        diagnosticCriteriaMetAtAdmissionWasReduced(val) {
            this.searchReportsDischargeDatadiagnosticCriteriaMetAtAdmissionWasReduced(val)
        },
        diagnosticCriteriaMetAtDischarge(val) {
            this.searchReportsDischargeDatadiagnosticCriteriaMetAtDischarge(val)
        },
        currentGamblingComparedToAdmission(val) {
            this.searchReportsDischargeDatacurrentGamblingComparedToAdmission(val)
        },
        clientInvolvedWithSelfHelpAtDischarge(val) {
            this.searchReportsDischargeDataclientInvolvedWithSelfHelpAtDischarge(val)
        },
        clientReferredForContinuedServices(val) {
            this.searchReportsDischargeDataclientReferredForContinuedServices(val)
        },
        relatedProblemsGambling(val) {
            this.searchReportsDischargeDatarelatedProblemsGambling(val)
        },
        relatedProblemsDrugUse(val) {
            this.searchReportsDischargeDatarelatedProblemsDrugUse(val)
        },
        relatedProblemsAlcoholUse(val) {
            this.searchReportsDischargeDatarelatedProblemsAlcoholUse(val)
        },
        relatedProblemsFinancial(val) {
            this.searchReportsDischargeDatarelatedProblemsFinancial(val)
        },
        relatedProblemsLegal(val) {
            this.searchReportsDischargeDatarelatedProblemsLegal(val)
        },
        relatedProblemsVocationalEducational(val) {
            this.searchReportsDischargeDatarelatedProblemsVocationalEducational(val)
        },
        relatedProblemsMedicalHealth(val) {
            this.searchReportsDischargeDatarelatedProblemsMedicalHealth(val)
        },
        relatedProblemsLethalitySelf(val) {
            this.searchReportsDischargeDatarelatedProblemsLethalitySelf(val)
        },
        relatedProblemsLethalityOther(val) {
            this.searchReportsDischargeDatarelatedProblemsLethalityOther(val)
        },
        relatedProblemsMentalHealth(val) {
            this.searchReportsDischargeDatarelatedProblemsMentalHealth(val)
        },
        relatedProblemsFamily(val) {
            this.searchReportsDischargeDatarelatedProblemsFamily(val)
        },
        arrayFiltradoReportsDischargeData(val) {
            this.countTotalDischarges = 0
            this.countTotalDischargeStatusPlanned = 0
            this.countTotalDischargeStatusDropout = 0
            this.countAverageSessionsCompleted = 0
            this.countGoalsAchieved = 0
            this.countGoalsPartiallyAchieved = 0
            this.countNoLongerGamblingorReduced = 0
            this.countDiagnosticCriteriaReduced = 0
            this.averageLengthOfStay = 0

            this.countDischargeSCTAllGM = 0
            this.countDischargeSCTHMGM = 0
            this.countDischargeTNCMBCD = 0
            this.countDischargeTNCSGM = 0
            this.countDischargeTNCNGM = 0
            this.countDischargeBAS = 0
            this.countDischargeCNRT = 0
            this.countDischargeCRI = 0
            this.countDischargeCTP = 0
            this.countDischargeMEC = 0
            this.countDischargeOther = 0
            this.countDischargeATTLOCNLN = 0
            this.countDischargeFTATLOCUTY = 0
            this.countDischargeLACAFRMO = 0
            this.countDischargeLACALCNRP = 0
            this.countDischargeLACATOTPF = 0
            this.countDischargeDDTNPR = 0
            this.countDischargeDDTNCV = 0
            this.countDischargeDDTNCPOC = 0
            this.countDischargeCAI = 0
            this.countDischargeCCNLPFMPR = 0
            this.countDischargeCD = 0
            this.countDischargeCR = 0
            this.countDischargePC = 0
            this.countDispositionNRM = 0
            this.countDispositionCNINOAS = 0
            this.countDispositionCRBTSP = 0
            this.countDispositionCRTOSP = 0
            this.countDispositionCRTMHP = 0
            this.countDispositionCRTOPGP = 0
            this.countDispositionCRR = 0
            this.countCGCTANA = 0
            this.countCGCTANLG = 0
            this.countCGCTARG = 0
            this.countCGCTASG = 0
            this.countCGCTAWG = 0
            this.countCRCSPGSHGM = 0
            this.countCRCSSUSHGM = 0
            this.countCRCSFC = 0
            this.countCRCSCS = 0
            this.countCRCSPGTS = 0
            this.countCRCSSUTS = 0
            this.countCRCSRSS = 0
            this.countCRCSO = 0

            var totalCountGoalsAchieved = 0
            var totalCountGoalsPartiallyAchieved = 0
            var totalCountNoLongerGamblingorReduced = 0
            var totalCountDiagnosticCriteriaReduced = 0
            var totalAverageSessionsCompleted = 0
            var totalAverageLengthOfStay = 0
            var arrayExport = []
            this.readyToExport = false

            val.forEach(dataClientActivity => {
                if(dataClientActivity.statusLogical === 'Discharged') {
                    // if(dataClientActivity.enterAdmissionDischargeInformation) {
                    // Total Discharges
                    // if((dataClientActivity.dateOfDischarge && dataClientActivity.dateOfDischarge !== 'N/A') && dataClientActivity.statusLogical === 'Discharged') {
                    // if(dataClientActivity.statusLogical === 'Discharged') {
                        // && dataClientActivity.status !== 'On Hold'
                        // console.log(dataClientActivity.id)
                        // idAdmDis
                        this.countTotalDischarges += 1
                    // }
                    
                    if(dataClientActivity.insurance === 'AARP' &&  dataClientActivity.county === 'Albany') {
                        // console.log(dataClientActivity.status, dataClientActivity.statusLogical)
                    }

                    if(dataClientActivity.dischargeStatus) {
                        // Total Planned Discharges
                        if(dataClientActivity.dischargeStatus === 'Planned') {
                            this.countTotalDischargeStatusPlanned += 1
                        }

                        // Total Dropout Discharges
                        if(dataClientActivity.dischargeStatus === 'Dropout') {
                            this.countTotalDischargeStatusDropout += 1
                        }
                    }

                    // Average Sessions Completed
                    if(dataClientActivity.numberOfSessionsAttended) {
                        totalAverageSessionsCompleted += 1
                        this.countAverageSessionsCompleted += parseInt(dataClientActivity.numberOfSessionsAttended, 10)
                    }
                    
                    if(dataClientActivity.dischargeStatus) {
                        if(dataClientActivity.dischargeStatus === 'Completed Treatment, All Goals Met' || dataClientActivity.dischargeStatus.includes('Completed Treatment, All Goals Met')) {
                            this.countDischargeSCTAllGM += 1
                        }

                        if(dataClientActivity.dischargeStatus === 'Completed Treatment, Half or More Goals Met' || dataClientActivity.dischargeStatus.includes('Completed Treatment, Half or More Goals Met')) {
                            this.countDischargeSCTHMGM += 1
                        }

                        if(dataClientActivity.dischargeStatus === 'Treatment Not Completed, Max Benefit/Clinical Decision' || dataClientActivity.dischargeStatus.includes('Treatment Not Completed, Max Benefit/Clinical Decision')) {
                            this.countDischargeTNCMBCD += 1
                        }

                        if(dataClientActivity.dischargeStatus === 'Treatment Not Completed, Some Goals Met' || dataClientActivity.dischargeStatus.includes('Treatment Not Completed, Some Goals Met')) {
                            this.countDischargeTNCSGM += 1
                        }

                        if(dataClientActivity.dischargeStatus === 'Treatment Not Completed, No Goals Met' || dataClientActivity.dischargeStatus.includes('Treatment Not Completed, No Goals Met')) {
                            this.countDischargeTNCNGM += 1
                        }
                    }

                    if(dataClientActivity.plannedDischargeReasons) {
                        if(dataClientActivity.plannedDischargeReasons === 'Barriers to accessing services' || dataClientActivity.plannedDischargeReasons.includes('Barriers to accessing services')) {
                            this.countDischargeBAS += 1
                        }

                        if(dataClientActivity.plannedDischargeReasons === 'Client not ready for treatment' || dataClientActivity.plannedDischargeReasons.includes('Client not ready for treatment')) {
                            this.countDischargeCNRT += 1
                        }

                        if(dataClientActivity.plannedDischargeReasons === 'Client reports improvement' || dataClientActivity.plannedDischargeReasons.includes('Client reports improvement')) {
                            this.countDischargeCRI += 1
                        }

                        if(dataClientActivity.plannedDischargeReasons === 'Completed treatment process (as determined by Clinician)' || dataClientActivity.plannedDischargeReasons.includes('Completed treatment process (as determined by Clinician)')) {
                            this.countDischargeCTP += 1
                        }

                        if(dataClientActivity.plannedDischargeReasons === 'Mandatory evaluation completed' || dataClientActivity.plannedDischargeReasons.includes('Mandatory evaluation completed')) {
                            this.countDischargeMEC += 1
                        }

                        if(dataClientActivity.plannedDischargeReasons === 'Other' || dataClientActivity.plannedDischargeReasons.includes('Other')) {
                            this.countDischargeOther += 1
                        }
                    }

                    if(dataClientActivity.dischargeDisposition) {
                        if(dataClientActivity.dischargeDisposition === 'Additional treatment at this level of care no longer necessary' || dataClientActivity.dischargeDisposition.includes('Additional treatment at this level of care no longer necessary')) {
                            this.countDischargeATTLOCNLN += 1
                        }

                        if(dataClientActivity.dischargeDisposition === 'Further treatment at this level of care unlikely to yield additional clinical gains' || dataClientActivity.dischargeDisposition.includes('Further treatment at this level of care unlikely to yield additional clinical gains')) {
                            this.countDischargeFTATLOCUTY += 1
                        }

                        if(dataClientActivity.dischargeDisposition === 'Left against clinical advice, Formal referral made/offered' || dataClientActivity.dischargeDisposition.includes('Left against clinical advice, Formal referral made/offered')) {
                            this.countDischargeLACAFRMO += 1
                        }

                        if(dataClientActivity.dischargeDisposition === 'Left against clinical advice, Lost contact, No referral possible' || dataClientActivity.dischargeDisposition.includes('Left against clinical advice, Lost contact, No referral possible')) {
                            this.countDischargeLACALCNRP += 1
                        }

                        if(dataClientActivity.dischargeDisposition === 'Left against clinical advice, Termination of third party funds' || dataClientActivity.dischargeDisposition.includes('Left against clinical advice, Termination of third party funds')) {
                            this.countDischargeLACATOTPF += 1
                        }

                        if(dataClientActivity.dischargeDisposition === 'Discharged due to non-compliance, Program Rules' || dataClientActivity.dischargeDisposition.includes('Discharged due to non-compliance, Program Rules')) {
                            this.countDischargeDDTNPR += 1
                        }

                        if(dataClientActivity.dischargeDisposition === 'Discharged due to non-compliance, Violence' || dataClientActivity.dischargeDisposition.includes('Discharged due to non-compliance, Violence')) {
                            this.countDischargeDDTNCV += 1
                        }

                        if(dataClientActivity.dischargeDisposition === 'Discharged due to non-compliance, Possession of contraband' || dataClientActivity.dischargeDisposition.includes('Discharged due to non-compliance, Possession of contraband')) {
                            this.countDischargeDDTNCPOC += 1
                        }

                        if(dataClientActivity.dischargeDisposition === 'Client arrested/incarcerated' || dataClientActivity.dischargeDisposition.includes('Client arrested/incarcerated')) {
                            this.countDischargeCAI += 1
                        }

                        if(dataClientActivity.dischargeDisposition === 'Client could no longer participate for medical/psychiatric reasons' || dataClientActivity.dischargeDisposition.includes('Client could no longer participate for medical/psychiatric reasons')) {
                            this.countDischargeCCNLPFMPR += 1
                        }

                        if(dataClientActivity.dischargeDisposition === 'Client death' || dataClientActivity.dischargeDisposition.includes('Client death')) {
                            this.countDischargeCD += 1
                        }

                        if(dataClientActivity.dischargeDisposition === 'Client relocated' || dataClientActivity.dischargeDisposition.includes('Client relocated')) {
                            this.countDischargeCR += 1
                        }

                        if(dataClientActivity.dischargeDisposition === 'Program closed' || dataClientActivity.dischargeDisposition.includes('Program closed')) {
                            this.countDischargePC += 1
                        }
                    }

                    if(dataClientActivity.referralDisposition) {
                        if(dataClientActivity.referralDisposition === 'No referral made' || dataClientActivity.referralDisposition.includes('No referral made')) {
                            this.countDispositionNRM += 1
                        }

                        if(dataClientActivity.referralDisposition === 'Client not in need of additional services' || dataClientActivity.referralDisposition.includes('Client not in need of additional services')) {
                            this.countDispositionCNINOAS += 1
                        }

                        if(dataClientActivity.referralDisposition === 'Client referred back to SUD program' || dataClientActivity.referralDisposition.includes('Client referred back to SUD program')) {
                            this.countDispositionCRBTSP += 1
                        }

                        if(dataClientActivity.referralDisposition === 'Client referred to other SUD program' || dataClientActivity.referralDisposition.includes('Client referred to other SUD program')) {
                            this.countDispositionCRTOSP += 1
                        }

                        if(dataClientActivity.referralDisposition === 'Client referred to mental health program' || dataClientActivity.referralDisposition.includes('Client referred to mental health program')) {
                            this.countDispositionCRTMHP += 1
                        }

                        if(dataClientActivity.referralDisposition === 'Client referred to other problem gambling program' || dataClientActivity.referralDisposition.includes('Client referred to other problem gambling program')) {
                            this.countDispositionCRTOPGP += 1
                        }

                        if(dataClientActivity.referralDisposition === 'Client refused referral' || dataClientActivity.referralDisposition.includes('Client refused referral')) {
                            this.countDispositionCRR += 1
                        }
                    }

                    if(dataClientActivity.currentGamblingComparedToAdmission) {
                        if(dataClientActivity.currentGamblingComparedToAdmission === 'N/A' || dataClientActivity.currentGamblingComparedToAdmission.includes('N/A')) {
                            this.countCGCTANA += 1
                        }

                        if(dataClientActivity.currentGamblingComparedToAdmission === 'No longer gambling' || dataClientActivity.currentGamblingComparedToAdmission.includes('No longer gambling')) {
                            this.countCGCTANLG += 1
                        }

                        if(dataClientActivity.currentGamblingComparedToAdmission === 'Reduced gambling' || dataClientActivity.currentGamblingComparedToAdmission.includes('Reduced gambling')) {
                            this.countCGCTARG += 1
                        }

                        if(dataClientActivity.currentGamblingComparedToAdmission === 'Same gambling' || dataClientActivity.currentGamblingComparedToAdmission.includes('Same gambling')) {
                            this.countCGCTASG += 1
                        }

                        if(dataClientActivity.currentGamblingComparedToAdmission === 'Worse gambling' || dataClientActivity.currentGamblingComparedToAdmission.includes('Worse gambling')) {
                            this.countCGCTAWG += 1
                        }
                    }
                    
                    if(dataClientActivity.clientReferredForContinuedServices) {
                        if(dataClientActivity.clientReferredForContinuedServices === 'Problem Gambling Self-Help Group Meetings' || dataClientActivity.clientReferredForContinuedServices.includes('Problem Gambling Self-Help Group Meetings')) {
                            this.countCRCSPGSHGM += 1
                        }

                        if(dataClientActivity.clientReferredForContinuedServices === 'Substance Use Self-Help Group Meetings' || dataClientActivity.clientReferredForContinuedServices.includes('Substance Use Self-Help Group Meetings')) {
                            this.countCRCSSUSHGM += 1
                        }

                        if(dataClientActivity.clientReferredForContinuedServices === 'Financial Counseling' || dataClientActivity.clientReferredForContinuedServices.includes('Financial Counseling')) {
                            this.countCRCSFC += 1
                        }

                        if(dataClientActivity.clientReferredForContinuedServices === 'Crisis Services' || dataClientActivity.clientReferredForContinuedServices.includes('Crisis Services')) {
                            this.countCRCSCS += 1
                        }

                        if(dataClientActivity.clientReferredForContinuedServices === 'Problem Gambling Treatment Services' || dataClientActivity.clientReferredForContinuedServices.includes('Problem Gambling Treatment Services')) {
                            this.countCRCSPGTS += 1
                        }

                        if(dataClientActivity.clientReferredForContinuedServices === 'Substance Use Treatment Services' || dataClientActivity.clientReferredForContinuedServices.includes('Substance Use Treatment Services')) {
                            this.countCRCSSUTS += 1
                        }

                        if(dataClientActivity.clientReferredForContinuedServices === 'Recovery Support Services' || dataClientActivity.clientReferredForContinuedServices.includes('Recovery Support Services')) {
                            this.countCRCSRSS += 1
                        }

                        if(dataClientActivity.clientReferredForContinuedServices === 'Other' || dataClientActivity.clientReferredForContinuedServices.includes('Other')) {
                            this.countCRCSO += 1
                        }
                    }

                    // if(dataClientActivity.goalsMet) {
                    //     // testTotal += 1
                    //     // Goals Achieved
                    //     if (dataClientActivity.goalsMet === 'Achieved') {
                    //         this.countGoalsAchieved += 1
                    //     }
                    //     dataClientActivity.goalsMet !== 'N/A' ? totalCountGoalsAchieved += 1 : ''
                    //     // totalCountGoalsAchieved += 1
                    //     // else {
                    //     //     totalCountGoalsAchieved += 1
                    //     // }
                        
                    //     // Goals Partially Achieved
                    //     if (dataClientActivity.goalsMet === 'Partially Achieved') {
                    //         this.countGoalsPartiallyAchieved += 1
                    //     }
                    //     // if(this.region) {
                    //     //     console.log(dataClientActivity.goalsMet)
                    //     // }
                        
                    //     dataClientActivity.goalsMet !== 'N/A' ? totalCountGoalsPartiallyAchieved += 1 : ''
                    //     // else {
                    //     //     totalCountGoalsPartiallyAchieved += 1
                    //     // }
                    // }

                    // No Longer Gambling or Reduced
                    if(dataClientActivity.currentGamblingComparedToAdmission === 'No longer gambling' || dataClientActivity.currentGamblingComparedToAdmission === 'Reduced gambling') {
                        this.countNoLongerGamblingorReduced += 1
                    }
                    // console.log(dataClientActivity.currentGamblingComparedToAdmission)
                    dataClientActivity.currentGamblingComparedToAdmission !== 'N/A' && dataClientActivity.currentGamblingComparedToAdmission !== '' ? totalCountNoLongerGamblingorReduced += 1 : ''
                    // totalCountNoLongerGamblingorReduced += 1
                    // else {
                    //     totalCountNoLongerGamblingorReduced += 1
                    // }

                    if(dataClientActivity.diagnosticCriteriaMetAtAdmissionWasReduced) {
                        // Diagnostic Criteria Reduced
                        if(dataClientActivity.diagnosticCriteriaMetAtAdmissionWasReduced === 'Yes') {
                            this.countDiagnosticCriteriaReduced += 1
                        }
                        dataClientActivity.diagnosticCriteriaMetAtAdmissionWasReduced !== 'N/A' ? totalCountDiagnosticCriteriaReduced += 1 : ''
                        // totalCountDiagnosticCriteriaReduced += 1
                        // else {
                        //     totalCountDiagnosticCriteriaReduced += 1
                        // }
                    }
                    
                    // average length of stay
                    if(dataClientActivity.admissionDate && dataClientActivity.dateOfDischargeFormat) {
                        var startFormat = moment(dataClientActivity.admissionDate,'MM-DD-YYYY').format('MM/DD/YYYY')
                        var endFormat = moment(dataClientActivity.dateOfDischargeFormat,'DD/MM/YYYY').format('MM/DD/YYYY')

                        var start = moment(startFormat)
                        var end = moment(endFormat)
                        // console.log(start,end)

                        // if() {28/09/2021 06/01/2022
                        var duration = moment.duration(end.diff(start));
                        var months = duration.asMonths();
                        // console.log(months)
                        this.averageLengthOfStay += months
                        // }
                        totalAverageLengthOfStay += 1
                    }
                    // }
                    arrayExport.push({
                        // id: dataClientActivity.,
                        // idAdmDis: dataClientActivity.,
                        // clientID: dataClientActivity.,
                        // clientName: dataClientActivity.,
                        status: dataClientActivity.statusLogical,
                        // statusLogical: dataClientActivity.,
                        contactDate: dataClientActivity.contactDate,
                        // contactDateFormat: dataClientActivity.,
                        admissionDate: dataClientActivity.admissionDate,
                        // dateOfDischargeFormat: dataClientActivity.,
                        enterAdmissionDischargeInformation: dataClientActivity.enterAdmissionDischargeInformation,
                        dateOfDischarge: dataClientActivity.dateOfDischarge,
                        dischargeStatus: dataClientActivity.dischargeStatus,
                        // numberOfSessionsAttended: dataClientActivity.numberOfSessionsAttended,
                        // goalsMet: dataClientActivity.goalsMet,
                        // currentGamblingComparedToAdmission: dataClientActivity.currentGamblingComparedToAdmission,
                        // diagnosticCriteriaMetAtAdmissionWasReduced: dataClientActivity.diagnosticCriteriaMetAtAdmissionWasReduced,
                        region: dataClientActivity.region,
                        clientStatusAdmission: dataClientActivity.clientStatusAdmission ? dataClientActivity.clientStatusAdmission : '',
                        primaryICD10: dataClientActivity.primaryICD10 ? dataClientActivity.primaryICD10 : '',
                        locadtrgCompleted: dataClientActivity.locadtrg ? dataClientActivity.locadtrg : '',
                        LOCADTRAssessmentID: dataClientActivity.LOCADTRAssessmentID ? dataClientActivity.LOCADTRAssessmentID : '',

                        // new fields
                        plannedDischargeReasons: dataClientActivity.plannedDischargeReasons ? dataClientActivity.plannedDischargeReasons : '',
                        unplannedDischargeReason: dataClientActivity.unplannedDischargeReason ? dataClientActivity.unplannedDischargeReason : '',
                        numberOfSessionsAttended: dataClientActivity.numberOfSessionsAttended ? dataClientActivity.numberOfSessionsAttended : '',
                        diagnosticCriteriaMetAtAdmissionWasReduced: dataClientActivity.diagnosticCriteriaMetAtAdmissionWasReduced ? dataClientActivity.diagnosticCriteriaMetAtAdmissionWasReduced : '',
                        diagnosticCriteriaMetAtDischarge: dataClientActivity.diagnosticCriteriaMetAtDischarge ? dataClientActivity.diagnosticCriteriaMetAtDischarge : '',
                        // goalsMet: dataClientActivity.goalsMet ? dataClientActivity.goalsMet : '',
                        currentGamblingComparedToAdmission: dataClientActivity.currentGamblingComparedToAdmission ? dataClientActivity.currentGamblingComparedToAdmission : '',
                        clientInvolvedWithSelfHelpAtDischarge: dataClientActivity.clientInvolvedWithSelfHelpAtDischarge ? dataClientActivity.clientInvolvedWithSelfHelpAtDischarge : '',
                        clientReferredForContinuedServices: dataClientActivity.clientReferredForContinuedServices ? dataClientActivity.clientReferredForContinuedServices : '',
                        ifSoWhatServicesWereRecommended: dataClientActivity.ifSoWhatServicesWereRecommended ? dataClientActivity.ifSoWhatServicesWereRecommended : '',
                        
                        // client information
                        clientID: dataClientActivity.clientID ? dataClientActivity.clientID.replace(/#/g, "") : '',
                        insurance: dataClientActivity.insurance ? dataClientActivity.insurance : '',
                        county: dataClientActivity.county ? dataClientActivity.county : '',
                        language: dataClientActivity.language ? dataClientActivity.language : '',
                        // gender: dataClientActivity.gender ? dataClientActivity.gender : '',
                        program: dataClientActivity.clinicianProgram ? dataClientActivity.clinicianProgram : '',

                        // DISCHARGE DATA
                        dischargeDisposition: dataClientActivity.dischargeDisposition ? dataClientActivity.dischargeDisposition : '',
                        referralDisposition: dataClientActivity.referralDisposition ? dataClientActivity.referralDisposition : '',
                        relatedProblemsGambling: dataClientActivity.relatedProblemsGambling ? dataClientActivity.relatedProblemsGambling : '',
                        relatedProblemsDrugUse: dataClientActivity.relatedProblemsDrugUse ? dataClientActivity.relatedProblemsDrugUse : '',
                        relatedProblemsAlcoholUse: dataClientActivity.relatedProblemsAlcoholUse ? dataClientActivity.relatedProblemsAlcoholUse : '',
                        relatedProblemsFinancial: dataClientActivity.relatedProblemsFinancial ? dataClientActivity.relatedProblemsFinancial : '',
                        relatedProblemsLegal: dataClientActivity.relatedProblemsLegal ? dataClientActivity.relatedProblemsLegal : '',
                        relatedProblemsVocationalEducational: dataClientActivity.relatedProblemsVocationalEducational ? dataClientActivity.relatedProblemsVocationalEducational : '',
                        relatedProblemsMedicalHealth: dataClientActivity.relatedProblemsMedicalHealth ? dataClientActivity.relatedProblemsMedicalHealth : '',
                        relatedProblemsLethalitySelf: dataClientActivity.relatedProblemsLethalitySelf ? dataClientActivity.relatedProblemsLethalitySelf : '',
                        relatedProblemsLethalityOther: dataClientActivity.relatedProblemsLethalityOther ? dataClientActivity.relatedProblemsLethalityOther : '',
                        relatedProblemsMentalHealth: dataClientActivity.relatedProblemsMentalHealth ? dataClientActivity.relatedProblemsMentalHealth : '',
                        relatedProblemsFamily: dataClientActivity.relatedProblemsFamily ? dataClientActivity.relatedProblemsFamily : '',
                    })
                }
            })
            // console.log(this.countNoLongerGamblingorReduced,totalCountNoLongerGamblingorReduced)
            // console.log(totalCountGoalsAchieved,this.countGoalsAchieved)
            // console.log(this.averageLengthOfStay,totalAverageLengthOfStay)
            this.averageLengthOfStay = this.averageLengthOfStay / totalAverageLengthOfStay
            this.countAverageSessionsCompleted = Math.trunc(this.countAverageSessionsCompleted / totalAverageSessionsCompleted)
            this.countGoalsAchieved = ((this.countGoalsAchieved*100)/totalCountGoalsAchieved).toFixed(2)
            this.countGoalsPartiallyAchieved = ((this.countGoalsPartiallyAchieved*100)/totalCountGoalsPartiallyAchieved).toFixed(2)
            this.countNoLongerGamblingorReduced = ((this.countNoLongerGamblingorReduced*100)/totalCountNoLongerGamblingorReduced).toFixed(2)
            this.countDiagnosticCriteriaReduced = ((this.countDiagnosticCriteriaReduced*100)/totalCountDiagnosticCriteriaReduced).toFixed(2)
            this.isBusy = false

            // this.json_data = val
            this.json_data = arrayExport
            this.readyToExport = true
        }
    },
    methods: {
        ...mapActions(['searchClientActivityName','searchClientActivityStatus','searchClientActivityClinicianName','searchReportsDischargeDataRegion','searchReportsDischargeDataProgress','searchReportsDischargeDataLanguage','searchReportsDischargeDataInsurance','searchClientActivityClinicianStatus','searchClientActivityClientID','searchReportsDischargeDataStartDate','searchReportsDischargeDataEndDate','setSnapshotClientActivityReportsDischargeData','searchReportsDischargeDataProgram','searchReportsDischargeDatadischargeStatus','searchReportsDischargeDataplannedDischargeReasons','searchReportsDischargeDatadischargeDisposition','searchReportsDischargeDatareferralDisposition','searchReportsDischargeDatadiagnosticCriteriaMetAtAdmissionWasReduced','searchReportsDischargeDatadiagnosticCriteriaMetAtDischarge','searchReportsDischargeDatacurrentGamblingComparedToAdmission','searchReportsDischargeDataclientInvolvedWithSelfHelpAtDischarge','searchReportsDischargeDataclientReferredForContinuedServices','searchReportsDischargeDatarelatedProblemsGambling','searchReportsDischargeDatarelatedProblemsDrugUse','searchReportsDischargeDatarelatedProblemsAlcoholUse','searchReportsDischargeDatarelatedProblemsFinancial','searchReportsDischargeDatarelatedProblemsLegal','searchReportsDischargeDatarelatedProblemsVocationalEducational','searchReportsDischargeDatarelatedProblemsMedicalHealth','searchReportsDischargeDatarelatedProblemsLethalitySelf','searchReportsDischargeDatarelatedProblemsLethalityOther','searchReportsDischargeDatarelatedProblemsMentalHealth','searchReportsDischargeDatarelatedProblemsFamily']),
        activeFilterFunction() {
            this.startDate = null
            this.endDate = null
            this.selectedRangeDate = 'month'
            this.region = ''
            this.language = ''
            this.progress = ''
            this.insurance = ''
            this.clinicianProgram = null
            // this.functionFilters()
            this.activeFilters = false
        },
        functionDateRange(val) {
            if(val === 'month') {
                // console.log(this.startDate)
                if(this.startDate) {
                    // '24/03/2022'
                    var formatDate1 = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                    var formatDate = moment(this.startDate,'DD/MM/YYYY').format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(1, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                } else {
                    var formatDate1 = moment(Date.now()).format('MM/DD/YYYY')
                    var formatDate = moment(Date.now()).format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(1, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(this.startDate,this.endDate)
                }
            } else if(val === 'quarter') {
                if(this.startDate) {
                    var formatDate1 = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                    var formatDate = moment(this.startDate,'DD/MM/YYYY').format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(3, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                } else {
                    var formatDate1 = moment(Date.now()).format('MM/DD/YYYY')
                    var formatDate = moment(Date.now()).format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(3, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                }
            } else if(val === 'year') {
                if(this.startDate) {
                    var formatDateNew = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                    var getYear = moment(formatDateNew).format('YYYY')
                    var formatDate1 = moment('01/01/'+getYear).format('MM/DD/YYYY')
                    var formatDate = '01/01/'+getYear
                    var expiredDate = moment(formatDate1).add(12, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                } else {
                    var getYear = moment(Date.now()).format('YYYY')
                    var formatDate1 = moment('01/01/'+getYear).format('MM/DD/YYYY')
                    var formatDate = moment('01/01/'+getYear).format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(12, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,dateUpdateFormat)
                }
            } else if(val === 'x') {
                this.selectedRangeDate = ''
                this.startDate = null
                this.endDate = null
            }
        },
        prevRange() {
            if(this.startDate && this.selectedRangeDate) {
                var formatDate = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                var subtractDate = moment(formatDate).subtract(1, 'months')
                var dateUpdateFormat = moment(subtractDate,'MM/DD/YYYY').format('DD/MM/YYYY')

                this.rangeDinamic = true
                this.startDate = dateUpdateFormat
                this.functionDateRange(this.selectedRangeDate)
            }
        },
        nextRange() {
            if(this.startDate && this.selectedRangeDate) {
                var formatDate = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                var subtractDate = moment(formatDate).add(1, 'months')
                var dateUpdateFormat = moment(subtractDate,'MM/DD/YYYY').format('DD/MM/YYYY')

                this.rangeDinamic = true
                this.startDate = dateUpdateFormat
                this.functionDateRange(this.selectedRangeDate)
            }
        },
        functionFilters() {
            if(this.clientActivityStatus) {
                this.searchClientActivityStatus(this.clientActivityStatus)
            } else {
                this.searchClientActivityStatus('')
            }

            if(this.startDate) {
                this.searchReportsDischargeDataStartDate(this.startDate)
            } else {
                this.searchReportsDischargeDataStartDate('')
            }

            if(this.endDate) {
                this.searchReportsDischargeDataEndDate(this.endDate)
            } else {
                this.searchReportsDischargeDataEndDate('')
            }

            if(this.region) {
                this.searchReportsDischargeDataRegion(this.region)
            } else {
                this.searchReportsDischargeDataRegion('')
            }

            if(this.progress) {
                this.searchReportsDischargeDataProgress(this.progress)
            } else {
                this.searchReportsDischargeDataProgress('')
            }

            if(this.language) {
                this.searchReportsDischargeDataLanguage(this.language)
            } else {
                this.searchReportsDischargeDataLanguage('')
            }

            if(this.insurance) {
                this.searchReportsDischargeDataInsurance(this.insurance)
            } else {
                this.searchReportsDischargeDataInsurance('')
            }

        }
    },
    created() {
        var user = auth.currentUser
        db.collection('usuarios').doc(user.uid).get()
        .then(doc => {
            this.isDataSpecialist = false
            if(doc.data().role === 'staff') {
                if(doc.data().permissions.includes('data specialist')) {
                    this.isDataSpecialist = true
                }
            } else if(doc.data().role === 'admin') {
                this.isDataSpecialist = true
            }
        })

        // filters
        this.startDate = this.searchReportsDischargeDataStartDateState ? this.searchReportsDischargeDataStartDateState : null
        this.endDate = this.searchReportsDischargeDataEndDateState ? this.searchReportsDischargeDataEndDateState : null
        this.region = this.searchReportsDischargeDataRegionState ? this.searchReportsDischargeDataRegionState : ''
        this.progress = this.searchReportsDischargeDataProgressState ? this.searchReportsDischargeDataProgressState : ''
        this.language = this.searchReportsDischargeDataLanguageState ? this.searchReportsDischargeDataLanguageState : ''
        this.insurance = this.searchReportsDischargeDataInsuranceState ? this.searchReportsDischargeDataInsuranceState : ''
        this.clinicianProgram = this.searchReportsDischargeDataProgramState ? this.searchReportsDischargeDataProgramState : null

        this.dischargeStatus = ''
        this.plannedDischargeReasons = ''
        this.dischargeDisposition = ''
        this.referralDisposition = ''
        this.diagnosticCriteriaMetAtAdmissionWasReduced = ''
        this.diagnosticCriteriaMetAtDischarge = ''
        this.currentGamblingComparedToAdmission = ''
        this.clientInvolvedWithSelfHelpAtDischarge = ''
        this.clientReferredForContinuedServices = ''
        this.relatedProblemsGambling = ''
        this.relatedProblemsDrugUse = ''
        this.relatedProblemsAlcoholUse = ''
        this.relatedProblemsFinancial = ''
        this.relatedProblemsLegal = ''
        this.relatedProblemsVocationalEducational = ''
        this.relatedProblemsMedicalHealth = ''
        this.relatedProblemsLethalitySelf = ''
        this.relatedProblemsLethalityOther = ''
        this.relatedProblemsMentalHealth = ''
        this.relatedProblemsFamily = ''

        this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/discharge-data/reports')
        .then(res => {
            this.countTotalDischarges = res.data.counter.countTotalDischarges
            this.countTotalDischargeStatusPlanned = res.data.counter.countTotalDischargeStatusPlanned
            this.countTotalDischargeStatusDropout = res.data.counter.countTotalDischargeStatusDropout
            this.countAverageSessionsCompleted = res.data.counter.countAverageSessionsCompleted
            this.countGoalsAchieved = res.data.counter.countGoalsAchieved
            this.countGoalsAchieved = ((res.data.counter.countGoalsAchieved*100)/res.data.counter.totalClientActivity).toFixed(2)
            this.countGoalsPartiallyAchieved = res.data.counter.countGoalsPartiallyAchieved
            this.countNoLongerGamblingorReduced = res.data.counter.countNoLongerGamblingorReduced
            this.countDiagnosticCriteriaReduced = res.data.counter.countDiagnosticCriteriaReduced
            this.totalClientActivity = res.data.counter.totalClientActivity
            this.isBusy = false
            // console.log(res.data)
            this.setSnapshotClientActivityReportsDischargeData(res.data.activity)
        })
        .catch(error => {
            console.log(error)
        })

        db.collection('clients').get()
        .then(docsClients => {
            this.totalNonEnglishClients = 0
            this.clientSatisfaction = 0
            docsClients.forEach(itemClient => {
                if(itemClient.data().status === 'active') {
                    var languageLowerCase = itemClient.data().primaryLanguage.toLowerCase()
                    if(!languageLowerCase.includes("english")) {
                        this.totalNonEnglishClients += 1
                    }
                }
            })
        })

        db.collection('taxonomies').doc('client').get()
        .then(docTaxonomia => {
            // profile fields
            // this.optionsRegion = docTaxonomia.data().region
            this.optionsLanguage = docTaxonomia.data().primaryLanguage
            this.optionsInsurance = docTaxonomia.data().insuranceType
        })

        db.collection('taxonomies').doc('clinician').get()
        .then(docTaxonomia => {
            // profile fields
            this.optionsRegion = docTaxonomia.data().region

            var arrayProgram = [{value: null, text: 'Any Program'}]
            docTaxonomia.data().program.forEach(item => {
                if(item !== 'OASAS') {
                    arrayProgram.push({value: item, text: item})
                }
            })
            this.optionsProgram = arrayProgram
        })
    }
}
</script>

<style>
.mx-input {
    height: 37px !important;
}
.form-control {
    background: #fff !important;
    background-color: #fff !important;
}
.range-date {
    padding: 8px;
    margin-top: 27px;
}
.vs__dropdown-option {
  white-space: normal !important;
}
.vs__dropdown-toggle {
  max-height: 38px;
}
.vs__selected-options {
  overflow: auto;
}
.vs__selected-options {
    overflow-x: hidden;
    overflow-y: hidden;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>